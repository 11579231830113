
import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../logo.png';
import FolatingShareButton from './FloatingShareButton';
import Instagram from '../../assets/instagram.svg';
import Whatsapp from '../../assets/whatsapp.svg';
import Youtube from '../../assets/youtube.svg';
function Footer() {
    return (
        <>
        <FolatingShareButton/>
        <footer className="bg-white dark:bg-gray-900 pt-10">
        <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
          <div className="md:flex md:justify-between">
            
            <div className="grid grid-cols-2 gap-3 sm:gap-16 sm:grid-cols-4">
              <div>
                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                  Resources
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-4">
                    <Link to="/about" style={{ fontSize: '12px' }} className="hover:underline">
                    About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" style={{ fontSize: '12px' }} className="hover:underline">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Quick Links
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-4">
                    <Link
                      to="/cars" style={{ fontSize: '12px' }}
                      className="hover:underline "
                    >
                     Find Your Cars
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/sell-your-car" style={{ fontSize: '12px' }}
                      className="hover:underline"
                    >
                      Sell Your Cars
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                  Legal
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-4">
                    <Link to="/privacy-policy" style={{ fontSize: '12px' }} className="hover:underline">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-condition" style={{ fontSize: '12px' }} className="hover:underline">
                      Terms &amp; Conditions
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h2 style={{ fontSize: '12px' }} className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                  Contact Us
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-4" style={{ fontSize: '12px' }}><b>Mob:</b> +91-7061777888 </li>
                  <li className="mb-4" style={{ fontSize: '12px' }}><b>Kolkata Office :</b> Shiv Green India Infra Developers Pvt. Ltd. 1409, Maduradaha, Near Anandapur P.S., Kolkata - 700107 </li>
                  <li className="mb-4" style={{ fontSize: '12px' }}><b>Patna Office :</b> 402, Surya Bageshwari, West Boring Canal Road, Patna - 800001</li>

                </ul>
              </div>
            </div>
          </div>
          <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
          <div className="sm:flex sm:items-center sm:justify-between">
            <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
              © 2023{" "}
              <a href="/" className="hover:underline">
              Your Car Bazar
              </a>
              . All Rights Reserved.
            </span>
            <div className="flex mt-4 sm:justify-center sm:mt-0">
              <a
                target="_blank" href="https://www.facebook.com/profile.php?id=100077091661827&mibextid=ZbWKwL"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 8 19"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Facebook page</span>
              </a>
             
              <a
                href="https://youtube.com/@yourcarbazar?si=6AEjhW-8MeNf8Qam" target="_blank"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
              >
                    <img src={Youtube} alt="youtube" className='h-4 w-4' />
                <span className="sr-only">Youtube</span>
              </a>
              <a
                href="https://www.instagram.com/your.carbazar?igsh=NHVkY2t5cDVpc25k" target="_blank"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
              >
               <img src={Instagram} alt="instagram" className='h-4 w-4' />
                <span className="sr-only">Instagram</span>
              </a>
              <a
                href="https://wa.me/917061777888" target="_blank"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5"
              >
               <img src={Whatsapp} alt="whatsapp" className='h-4 w-4' />
                <span className="sr-only">Whatsapp</span>
              </a>
             
            </div>
          </div>
        </div>
      </footer>
        </>
      
    )
}

export default Footer
