import { React, useEffect, useState } from "react";
import ContactImage from "../../assets/contact.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
const Contact = () => {
  useEffect(() => {
    AOS.init({
      offset: 300,
      duration: 500,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    mobile: "",
    subject: "",
  });
  const [formDataError, setFormDataError] = useState({
    name: "",
    email: "",
    message: "",
    mobile: "",
    subject: "",
  });
  const apiUrl = process.env.REACT_APP_API_URL;
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(apiUrl + "storeContactInfo", formData);
      console.log(response.data);
      setFormData({
        name: "",
        email: "",
        message: "",
        mobile: "",
        subject: "",
      });
      setFormDataError({
        name: "",
        email: "",
        message: "",
        mobile: "",
        subject: "",
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const validationErrors = error.response.data.errors;
        const newErrors = {};
        Object.keys(validationErrors).forEach((key) => {
          newErrors[key] = validationErrors[key][0];
        });
        setFormDataError(newErrors);
      } else {
        console.error("Error saving data:", error);
      }
    }
  };

  return (
    <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0">
    <div className="px-5 py-3 my-4 bg-white rounded">
        <div className="flex flex-col">
          <h3 className="text-fill-base text-[16px] lg:leading-7 font-bold uppercase">
            {"Contact With Us"}
          </h3>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 gap-8 px-8 py-16 mb-10 text-gray-900 bg-white rounded-lg shadow-lg md:grid-cols-2 md:px-12 lg:px-16 xl:px-32">
          <div className="flex flex-col justify-between">
            <div>
              <h2 className="text-4xl font-bold leading-tight lg:text-5xl">
                Lets talk about everything!
              </h2>
            </div>
            <div className="text-center ">
              <img src={ContactImage} alt="contact" className="aspect-auto" />
            </div>
          </div>
          <div className="">
            <div>
              <span className="text-sm font-bold text-gray-600 uppercase">
                Full Name
              </span>
              <input
                className="bg-gray-50 border py-2 px-4 w-full appearance-none transition duration-150 ease-in-out border text-input text-13px lg:text-sm font-body rounded placeholder-[#B3B3B3] min-h-12  text-primary-dark focus:ring-0  border-border-two focus:border-1 focus:outline-none focus:border-primary h-11 md:h-12"
                type="text"
                name="name"
                onChange={handleChange}
                value={formData.name}
                placeholder="Enter Name"
              />
              {formDataError.name !== "" && (
                <span className="text-red-600 error">{formDataError.name}</span>
              )}
            </div>
            <div className="mt-2">
              <span className="text-sm font-bold text-gray-600 uppercase">
                Email
              </span>
              <input
                className="bg-gray-50 border py-2 px-4 w-full appearance-none transition duration-150 ease-in-out border text-input text-13px lg:text-sm font-body rounded placeholder-[#B3B3B3] min-h-12  text-primary-dark focus:ring-0  border-border-two focus:border-1 focus:outline-none focus:border-primary h-11 md:h-12"
                type="text"
                name="email"
                placeholder="Enter Email"
                onChange={handleChange}
                value={formData.email}
              />
              {formDataError.email !== "" && (
                <span className="text-red-600 error">
                  {formDataError.email}
                </span>
              )}
            </div>
            <div className="mt-2">
              <span className="text-sm font-bold text-gray-600 uppercase">
                Mobile
              </span>
              <input
                className="bg-gray-50 border py-2 px-4 w-full appearance-none transition duration-150 ease-in-out border text-input text-13px lg:text-sm font-body rounded placeholder-[#B3B3B3] min-h-12  text-primary-dark focus:ring-0  border-border-two focus:border-1 focus:outline-none focus:border-primary h-11 md:h-12"
                type="text"
                name="mobile"
                placeholder="Enter Mobile"
                onChange={handleChange}
                value={formData.mobile}
              />
              {formDataError.mobile !== "" && (
                <span className="text-red-600 error">
                  {formDataError.mobile}
                </span>
              )}
            </div>
            <div className="mt-2">
              <span className="text-sm font-bold text-gray-600 uppercase">
                Subject
              </span>
              <input
                className="bg-gray-50 border py-2 px-4 w-full appearance-none transition duration-150 ease-in-out border text-input text-13px lg:text-sm font-body rounded placeholder-[#B3B3B3] min-h-12  text-primary-dark focus:ring-0  border-border-two focus:border-1 focus:outline-none focus:border-primary h-11 md:h-12"
                type="text"
                name="subject"
                placeholder="Enter Subject"
                onChange={handleChange}
                value={formData.subject}
              />
              {formDataError.subject !== "" && (
                <span className="text-red-600 error">
                  {formDataError.subject}
                </span>
              )}
            </div>
            <div className="mt-2">
              <span className="text-sm font-bold text-gray-600 uppercase">
                Message
              </span>
              <textarea
                className="bg-gray-50 border py-2 px-4 w-full appearance-none transition duration-150 ease-in-out border text-input text-13px lg:text-sm font-body rounded placeholder-[#B3B3B3] min-h-12  text-primary-dark focus:ring-0  border-border-two focus:border-1 focus:outline-none focus:border-primary h-11 md:h-12"
                placeholder="Enter Message"
                name="message"
                onChange={handleChange}
                value={formData.message}
              />
              {formDataError.message !== "" && (
                <span className="text-red-600 error">
                  {formDataError.message}
                </span>
              )}
            </div>
            <div className="mt-2">
              <button className="w-full p-3 text-sm font-bold tracking-wide text-gray-100 uppercase bg-indigo-500 rounded-lg focus:outline-none focus:shadow-outline">
                Send Message
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Contact;
