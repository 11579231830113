import React from "react";
const CalculateCarEmi = ({ totalPrice, downPaymentPercentage = 1 }) => {
  const carAmount = parseInt(totalPrice);
  const interestRate = 9.5;
  const downPayment = (totalPrice * downPaymentPercentage) / 100;
  const tenure = 4;

  const calculateEmiForTenure = (loanAmount, years) => {
    const totalInterest = (loanAmount * interestRate * years) / 100;
    const totalAmount = loanAmount + totalInterest;
    const totalMonths = years * 12;
    const monthlyEmi = totalAmount / totalMonths;
    return monthlyEmi.toFixed(2);
  };

  return (
    <p className="text-red-500 mt-[10px] text-[14px] font-bold">{calculateEmiForTenure(carAmount - downPayment, tenure)} / Monthly EMI</p>
  );
};

export default CalculateCarEmi;
