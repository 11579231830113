
import {React, useEffect} from 'react'
import Header from './partials/Header';
import Footer from './partials/Footer';
import Review from './partials/Review';
import About from './partials/About';
const HowItWorks = () => {
    useEffect(() => {
        document.title = 'How Your Car Bazar Works'
      }, []);

    return (
        <div>
            <Header />
            <About />
            <Review />
            <Footer />
        </div>
    )
}

export default HowItWorks