import React, { useState, useEffect } from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
const EmiCalculator = ({ totalPrice, downPaymentPercentage = 1 }) => {
  const downPaymentAmount = (totalPrice * downPaymentPercentage) / 100;
  const carAmount = parseInt(totalPrice);
  const interestRate = 9.5;
  const [downPayment, setDownPayment] = useState(downPaymentAmount);
  const [tenure, setTenure] = useState(4);
  const [activeTab, setActiveTab] = useState("EMI");
  const [emi, setEmi] = useState(0);
  const [breakdown, setBreakdown] = useState({});
  const [yearWiseEmi, setYearWiseEmi] = useState([]);

  const calculateEmiForTenure = (loanAmount, years) => {
    const totalInterest = (loanAmount * interestRate * years) / 100;
    const totalAmount = loanAmount + totalInterest;
    const totalMonths = years * 12;
    const monthlyEmi = totalAmount / totalMonths;
    return monthlyEmi.toFixed(2);
  };

  const calculateBreakdown = () => {
    const loanAmount = carAmount - downPayment;
    const breakdownData = {
      loanAmount,
      interestAmount: ((loanAmount * interestRate * tenure) / 100).toFixed(2),
      payableAmount: (
        loanAmount +
        (loanAmount * interestRate * tenure) / 100
      ).toFixed(2),
    };
    setBreakdown(breakdownData);
  };

  const calculateYearWiseEmi = () => {
    const loanAmount = carAmount - downPayment;
    const emiData = [];
    for (let year = 1; year <= 5; year++) {
      const emiForYear = calculateEmiForTenure(loanAmount, year);
      emiData.push({ year, emi: emiForYear, months: year * 12 });
    }
    setYearWiseEmi(emiData);
  };

  useEffect(() => {
    calculateBreakdown();
    calculateYearWiseEmi();
    setEmi(calculateEmiForTenure(carAmount - downPayment, tenure));
  }, [downPayment, tenure]);

  // Pie Chart Data
  const pieData = [
    { name: "Down Payment", value: parseInt(downPayment) },
    {
      name: "Loan Amount",
      value: parseInt(breakdown.loanAmount) || 0,
    },
    {
      name: "Payable Amount",
      value: parseInt(breakdown.payableAmount) || 0,
    },
  ];

  return (
    <div className="p-5 bg-white rounded shadow">
      {/* Tabs on Top */}
      <div className="flex mb-4 space-x-4">
        <button
          onClick={() => setActiveTab("EMI")}
          className={`py-2 px-4 rounded-lg ${
            activeTab === "EMI"
              ? "bg-primary text-white"
              : "bg-slate-200 text-gray-800"
          }`}
        >
          EMI
        </button>
        <button
          onClick={() => setActiveTab("Breakdown")}
          className={`py-2 px-4 rounded-lg ${
            activeTab === "Breakdown"
              ? "bg-primary text-white"
              : "bg-slate-200 text-gray-800"
          }`}
        >
          Breakdown
        </button>
        <button
          onClick={() => setActiveTab("YearWiseEmi")}
          className={`py-2 px-4 rounded-lg ${
            activeTab === "YearWiseEmi"
              ? "bg-primary text-white"
              : "bg-slate-200 text-gray-800"
          }`}
        >
          Year-wise EMI
        </button>
      </div>

      {/* EMI Tab */}
      {activeTab === "EMI" && (
        <div>
          {/* Car Amount and Down Payment */}
          <div className="mb-4">
            <label className="block mb-2 text-xl font-semibold text-gray-800">
              Car Amount
            </label>
            <p className="text-lg font-bold text-black">
              ₹ {carAmount.toFixed(2)}
            </p>
          </div>

          {/* Down Payment Slider */}
          <div className="mb-6">
            <label className="block mb-2 text-lg font-semibold text-gray-800">
              Down Payment: ₹{downPayment.toLocaleString()}
            </label>
            <input
              type="range"
              min={downPaymentAmount}
              max={carAmount}
              step="10000"
              value={downPayment}
              onChange={(e) => setDownPayment(Number(e.target.value))}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
            />
            <div className="flex justify-between mt-1 text-gray-600">
              <span>₹{downPaymentAmount.toFixed(2)}</span>
              <span>₹{carAmount.toFixed(2)}</span>
            </div>
          </div>

          {/* Tenure Selection */}
          <div className="mb-6">
            <label className="block mb-2 text-lg font-semibold text-gray-800">
              Tenure (Years)
            </label>
            <div className="flex space-x-4">
              {[1, 2, 3, 4, 5].map((year) => (
                <button
                  key={year}
                  onClick={() => setTenure(year)}
                  className={`py-2 px-4 rounded-lg ${
                    tenure === year
                      ? "bg-primary text-white"
                      : "bg-gray-200 text-gray-800"
                  } transition-colors`}
                >
                  {year} Year{year > 1 ? "s" : ""}
                </button>
              ))}
            </div>
          </div>

          {/* Estimated EMI */}
          {emi > 0 && (
            <div className="p-4 mt-6 rounded-lg bg-blue-50">
              <h3 className="text-2xl font-semibold text-blue-600">
                Estimated EMI
              </h3>
              <p className="mt-2 text-3xl font-bold text-blue-700">
                ₹{emi} / month
              </p>
            </div>
          )}
        </div>
      )}

      {/* Breakdown Tab */}
      {activeTab === "Breakdown" && (
        <div className="p-4 mt-6 bg-gray-100 rounded-lg">
          <h3 className="text-xl font-semibold text-blue-600">Breakdown</h3>
          <p className="mt-2 text-[15px] text-black relative before:content-[''] before:inline-block before:w-3 before:h-3 before:bg-blue-500 before:rounded-full before:mr-2">
            Down Payment: ₹{downPayment.toLocaleString()}
          </p>
          <p className="mt-2 mt-2 text-[15px] text-black relative before:content-[''] before:inline-block before:w-3 before:h-3 before:bg-green-500 before:rounded-full before:mr-2">
            Loan Amount: ₹{breakdown.loanAmount?.toLocaleString()}
          </p>
          <p className="mt-2 font-bold text-[15px] text-black relative before:content-[''] before:inline-block before:w-3 before:h-3 before:bg-yellow-500 before:rounded-full before:mr-2">
            Payable Amount: ₹{breakdown.payableAmount?.toLocaleString()}
          </p>

          {/* Pie Chart */}
          <PieChartComponent data={pieData} />
        </div>
      )}

      {/* Year-wise EMI Tab */}
      {activeTab === "YearWiseEmi" && (
        <div className="p-4 mt-6 bg-gray-100 rounded-lg">
          <h3 className="text-xl font-semibold text-blue-600">Year-wise EMI</h3>
          <table className="w-full mt-4 table-auto">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left">Year</th>
                <th className="px-4 py-2 text-left">Monthly EMIs</th>
              </tr>
            </thead>
            <tbody>
              {yearWiseEmi.map(({ year, emi, months }) => (
                <tr key={year}>
                  <td className="px-4 py-2">{year}</td>
                  <td className="px-4 py-2">
                    ₹{emi} / month for {months} months
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default EmiCalculator;

const PieChartComponent = ({ data }) => {
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];
  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={70}
          outerRadius={100}
          paddingAngle={5}
          dataKey="value"
          label
          stroke="#fff"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};
