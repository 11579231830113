import HeroBannerCard from "./HeroBannerCard";
import Carousel from "./CarouselBuilder";
import { SwiperSlide } from "swiper/react";

const HeroSliderBlock = ({
  variant = "slider",
  className = "mb-7",
  contentClassName = "px-5 py-10 xl:py-24",
  data,
  soldout = false,
  type = "static",
}) => {
  return (
    <div className={`${className}`}>
      <Carousel
        pagination={{
          clickable: true,
        }}
        navigation={true}
        autoplay={false}
        prevActivateId={`prevActivateId`}
        nextActivateId={`nextActivateId`}
      >
        {data?.map((banner) => (
          <SwiperSlide key={`banner--key${banner.id}`}>
            <HeroBannerCard
              soldout={soldout}
              type={type}
              banner={banner}
              variant={variant}
              className={contentClassName}
            />
          </SwiperSlide>
        ))}
      </Carousel>
    </div>
  );
};

export default HeroSliderBlock;
