import React from "react";

const Shimmer = () => {
  return (
    <div className=" p-4 bg-slate-200 rounded-md animate-pulse ">
      <div className="h-32 mb-4 bg-gray-300 rounded-md"></div>
      <div className="h-4 mb-2 bg-gray-300 rounded"></div>
      <div className="w-3/4 h-4 bg-gray-300 rounded"></div>
    </div>
  );
};

export default Shimmer;
