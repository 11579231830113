import { React, useEffect } from "react";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import ServiceCard from "./partials/ServiceCard";
import About from "./partials/About";
import Review from "./partials/Review";
import AllCarList from "./partials/AllCarList";
import Contact from "./partials/Contact";
import HeroSliderBlock from "./hero/HeroSliderBlock";
import BannerGrid from "./banner/BannerGrid";
import Banner1 from "../assets/banner/banner-1.jpg";
import Banner2 from "../assets/banner/banner-2.webp";
import Slider1 from "../assets/slider/slider-1.avif";
import Slider2 from "../assets/slider/slider-2.webp";
function Home() {
  useEffect(() => {
    document.title = "Your Car Bazar";
  }, []);
  const sliderData = [
    {
      id: 1,
      image: Slider1,
        description: 'Affordable, sleek, reliable.',
        title: 'Drive Your Dream Car, Today!',
        btnUrl: '/cars', 
        btnText: 'Explore'
    },
    {
      id: 2,
      image:Slider2, 
         description: 'Buy Your Dream Car',
        title: 'Turn Your Dream Into Reality!',
         btnUrl: '/cars', 
        btnText: 'Explore'
    },
  ];
  return (
    <div>
      <Header />
      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 xl:pt-8">
        <div className="grid xl:gap-[5px] grid-cols-1 xl:grid-cols-12">
          <HeroSliderBlock
          data={sliderData}
            variant="slider"
            className={`xl:col-span-8 mb-5 xl:mb-12`}
            contentClassName="p-7 sm:py-18 xl:py-16 sm:px-16 xl:px-24 md:h-[270px] xl:h-[375px] rounded"
          />
          <BannerGrid
            grid={1}
            images={[
              {
                image: Banner1,
                id: 1,
              },
              {
                id: 2,
                image: Banner2,
              },
            ]}
            girdClassName="xl:gap-[5px]"
            className="mb-5 xl:col-span-4 xl:mb-12"
          />
        </div>
      </div>
      <AllCarList heading={"Find Your Car"} filter={true} renderButton={false} slice={false} />
      <AllCarList heading="Sold Out Cars" soldout={true} length={5} />
      <ServiceCard />
      <About />
      <Review />
      <Contact />
      <Footer />
    </div>
  );
}

export default Home;
