import React from 'react';
import { useState, useEffect } from 'react';
import { RiShareLine } from 'react-icons/ri';

const FloatingShareButton = () => {
  const [currentPageUrl, setCurrentPageUrl] = useState('');

  useEffect(() => {
    setCurrentPageUrl(window.location.href);
  }, []);

  const sharePage = () => {
    if (navigator.share) {
      navigator.share({
        title: document.title,
        text: 'Check out this page!',
        url: currentPageUrl
      }).then(() => {
        console.log('Thanks for sharing!');
      }).catch(err => {
        console.log(
          "Error while using Web share API:");
        console.log(err);
      });
    } else {
      alert("Browser doesn't support this API !");
    }


  };

  return (
    <div className="fixed bottom-8 right-8 z-50">
      <button
        onClick={sharePage}
        className="bg-red-500 hover:bg-blue-600 text-white font-bold rounded-full h-10 w-10 flex items-center justify-center"
      >
        <RiShareLine />
      </button>
    </div>
  );
};

export default FloatingShareButton;