import React from 'react'
import Header from './partials/Header';
import Footer from './partials/Footer';
const PrivacyAndPolicy = () => {
  return (
    <div>
      <Header />
      <div className="container mx-auto p-4 my-4 max-w-screen-lg">
      <h2 className="text-2xl font-bold mb-4">Privacy Policy</h2>
      <p className="mb-4">
        This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in online used cars. This policy is not applicable to any information collected offline or via channels other than this website.
      </p>
      <p className="mb-4">
        The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.
      </p>
      <p className="mb-4">
        If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.
      </p>
      <p className="mb-4">
        When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.
      </p>
    </div>
      <Footer />
    </div>
  )
}

export default PrivacyAndPolicy
