import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronDown } from "react-icons/fa6";
const NavLinks = () => {
    const [heading, setHeading] = useState("");
    const getIconComponent = (iconName) => {
        switch (iconName) {
            case 'FaChevronDown':
                return <FaChevronDown className=' font-[10px]  hidden md:inline-block ' />;
            default:
                return null;
        }
    };
    const links = [
        { name: 'Home', link: '/' },
        {
            name: 'About',
            icon: 'FaChevronDown',
            submenu: true,
            sublinks: [
                {
                    sublink: [
                        { name: 'About Us', link: '/about' },
                        { name: 'How it Works', link: '/how-it-works' },
                    ]
                }
            ]
        },
        { name: 'Used Vehicle', link: '/cars' },
        { name: 'Sell Vehicle', link: '/sell-your-car' },
        { name: 'Contact Us', link: '/contact' },
        { name: 'Customer Feedback', link: '/feedback' },
    ];
    return (
        <>
            {
                links.map((link, index) => {
                    return (
                        <div key={index}>
                            <div className='px-4 py-3 text-left md:cursor-pointer group'>
                                <li className='flex items-center gap-1 font-bold hover:text-accent text-grey-500' onClick={() => heading === link.name ? setHeading('') : setHeading(link.name)}>
                                    {link.link ? (
                                        <Link to={link.link}>
                                            {link.name} {link.icon ? getIconComponent(link.icon) : null}
                                        </Link>
                                    ) : (
                                        <>
                                            {link.name} {link.icon ? getIconComponent(link.icon) : null}
                                        </>
                                    )}
                                </li>    {
                                    link.submenu &&
                                    <div className='absolute hidden group-hover:md:block' style={{ zIndex: 2000 }} key={index}>
                                        <div className='py-9'>
                                            <div className="absolute w-4 h-4 mt-1 rotate-45 bg-white left-3 top-6"></div>
                                            <div className="bg-white p-3.5 rounded   gap-3"> {/* grid grid-cols-2 */}
                                                {
                                                    link.sublinks.map((mysublinks, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <h1 key={index} className='mb-3 text-sm font-bold text-grey-500'>{mysublinks.head ? mysublinks.head : null}</h1>
                                                                {
                                                                    mysublinks.sublink.map((slink, index) => {
                                                                        return (
                                                                            <li key={index} className='pb-2 pr-4 text-grey-500'>
                                                                                <Link key={index} className=' hover:text-accent font-[12px]' to={slink.link}>{slink.name}</Link>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            {/* Mobile Nav */}

                            <div key={index} className={`z-10 ${heading === link.name ? 'md:hidden' : 'hidden'}`}>
                                {
                                    link.submenu && link.sublinks.map((mysublinks, index) => {
                                        return (
                                            <div key={index}>
                                                <h1 className='my-2 text-sm font-bold px-7'>{mysublinks.head ? mysublinks.head : null}</h1>
                                                {
                                                    mysublinks.sublink.map((slink, index) => {
                                                        return (
                                                            <li key={index} className='px-9 py-[5px]'>
                                                                <Link key={index} className='text-grey-500 hover:text-accent font-[12px]' to={slink.link}>{slink.name}</Link>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    );
                })
            }
        </>
    )
}

export default NavLinks