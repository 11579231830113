
import React from 'react';
const Review = () => {
  const reviews = [
    {
      id: 1,
      name: "Aarav Sharma",
      rating: 5,
      comment: "I recently purchased a pre-owned car from Your Car Bazar, and I must say, I'm thoroughly impressed with the quality of service and the condition of the vehicle. The staff was extremely helpful throughout the process, guiding me with transparent information about the car's history and maintenance. I got a great deal on a reliable car, and I couldn't be happier with my purchase. I highly recommend Your Car Bazar to anyone looking for a trustworthy place to buy their next vehicle.",
    },
    {
      id: 2,
      name: "Priya Patel",
      rating: 5,
      comment: "I had been searching for a used car for quite some time, and I stumbled upon Your Car Bazar online. From the moment I walked in, I felt welcomed and at ease. The sales team was knowledgeable and patient, answering all my questions and addressing any concerns I had. They helped me find the perfect car within my budget, and the entire buying process was smooth and hassle-free. I'm thrilled with my purchase and grateful for the exceptional service I received from Your Car Bazar. Thank you!",
    },
    {
      id: 3,
      name: " Siddharth Singhania",
      rating: 5,
      comment: "I recently bought a second-hand car from Your Car Bazar, and I couldn't be more satisfied with my experience. The staff went above and beyond to ensure that I found a vehicle that met my needs and preferences. They provided me with detailed information about the car's features and maintenance history, giving me peace of mind about my purchase. The car itself is in excellent condition, and I got it at a fantastic price. Overall, I'm extremely happy with my decision to buy from Your Car Bazar, and I would definitely recommend them to anyone in the market for a used car.",
    }
  ];

  return (
    <div className='mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0'>
      <div className="px-5 py-3 my-4 bg-white rounded">
        <div className="flex flex-col">
          <h3 className="text-fill-base text-[16px] lg:leading-7 font-bold uppercase">
            {"What Our Customer Says"}
          </h3>
        </div>
      </div>
      <div className="container flex justify-center pb-10 mx-auto">
        <div className="grid justify-center grid-cols-1 gap-4 mx-5 sm:mx-0 sm:grid-cols-2 lg:grid-cols-3">
          {reviews.map(review => (
            <div key={review.id} className="w-full max-w-md overflow-hidden bg-white shadow-md rounded-xl">
              <div className="p-6">
                <div className="flex items-center mb-4">
                  <img className="w-12 h-12 mr-4 rounded-full" src="https://via.placeholder.com/150" alt={review.name} />
                  <div>
                    <div className="text-sm font-semibold tracking-wide text-indigo-500 uppercase">{review.name}</div>
                    <div className="text-gray-600">{review.rating} Stars</div>
                  </div>
                </div>
                <p className="text-gray-700">{review.comment}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Review;