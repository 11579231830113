import React, { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import Search from "./Search";
import { BsFillFuelPumpDieselFill } from "react-icons/bs";
import { MdEmojiTransportation } from "react-icons/md";
import { GiPathDistance } from "react-icons/gi";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import SoldOutImage from "../../assets/soldout.webp";
import ShimmerCardList from "../shimmer/ShimmerCardList";
import CalculateCarEmi from "../emi/CalculateCarEmi";
const AllCarList = ({
  heading,
  filter = false,
  soldout = false,
  renderButton = true,
  slice = true,
  length = 20,
}) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiBaseUri = process.env.REACT_APP_BASE_URL;
  const [carData, setCarData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    AOS.init({
      offset: 50,
      duration: 500,
      easing: "ease-in-sine",
      delay: 100,
    });
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          apiUrl + "getCarData" + (soldout ? "?status=Sold Out" : "")
        );
        if (
          response.data &&
          response.data.data &&
          response.data.data.length > 0
        ) {
          setCarData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching car data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });
  const constructUrl = (updatedParams) => {
    let url = apiUrl + "getCarData?";

    if (updatedParams.districtSelected !== undefined) {
      url += "location=" + updatedParams.districtSelected + "&";
    }

    if (updatedParams.modelSelected !== undefined) {
      url += "model_id=" + updatedParams.modelSelected + "&";
    }

    if (updatedParams.fuelTypeSelected !== undefined) {
      url += "fuel_type=" + updatedParams.fuelTypeSelected;
    }
    if (url.endsWith("&")) {
      url = url.slice(0, -1);
    }

    return url;
  };
  const handleSearchChange = async (updatedParams) => {
    try {
      const response = await axios.get(constructUrl(updatedParams));
      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        setCarData(response.data.data);
      } else {
        alert("No Data Found!");
      }
    } catch (error) {
      console.error("Error fetching car data:", error);
    }
  };
  let carList;

  if (slice === true) {
    carList = soldout ? carData.slice(0, 5) : carData.slice(0, 20);
  } else {
    carList = carData;
  }

  return (
    <div>
      {isLoading && (
        <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mb-5">
          <div className=" py-3  mb-1.5 rounded bg-white px-5">
            <h3 className="text-fill-base text-[16px] lg:leading-7 font-bold uppercase">
              {heading}
            </h3>
          </div>
          <ShimmerCardList length={length} />
        </div>
      )}
      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0">
        {carList.length > 0 && (
          <div className=" py-3  mb-1.5 rounded bg-white px-5">
            <h3 className="text-fill-base text-[16px] lg:leading-7 font-bold uppercase">
              {heading}
            </h3>
          </div>
        )}
        {filter && !soldout && (
          <div className="px-5 py-4 bg-white rounded">
            <Search onSearchChange={handleSearchChange} />{" "}
          </div>
        )}
        <div className="grid grid-cols-1 xl:grid-cols-5 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-3">
          {carList.map((car) => (
            <Link
              to={
                "/car-detail/" +
                car.id +
                "/" +
                createSlug(
                  car.vehicle_model
                    ? car.vehicle_model.name
                    : "" +
                        "-" +
                        (car.vehicle_variant.name
                          ? car.vehicle_variant.name
                          : null)
                )
              }
              key={car.id}
            >
              <div data-aos="flip-up" key={car.id} className="p-2 md:p-4 group">
                <div className="overflow-hidden bg-white rounded-lg shadow-lg min:h-[300px]">
                  <div className="relative overflow-hidden bg-slate-200">
                    <img
                      src={`${apiBaseUri}file/uploads/${btoa(
                        "car-image"
                      )}/${btoa(
                        car.latest_image.image
                          ? car.latest_image.image
                          : "54654"
                      )}`}
                      alt={car.model}
                      className="object-cover w-full duration-300 h-80 md:h-48 group-hover:scale-110"
                    />
                    {soldout ? SoldOutBadge() : null}
                  </div>
                  <div className="p-4">
                    <h3 className="mb-2 text-[12px] font-semibold text-gray-800 truncate">
                      {car.vehicle_model !== null ? car.vehicle_model.name : ""}{" "}
                      - {car.vehicle_variant ? car.vehicle_variant.name : null}
                    </h3>

                    <div className="flex flex-wrap gap-4">
                      <div className="flex items-center">
                        <BsFillFuelPumpDieselFill className="mr-1 text-primary" />
                        <p className="flex items-center text-[10px] text-slate-600 bg-green-200 px-[6px] py-[1px] rounded-full">
                          {car.fuel_type}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <MdEmojiTransportation className="mr-1 text-primary" />
                        <p className="flex items-center text-[10px] text-slate-600 bg-green-200 px-[6px] py-[1px] rounded-full">
                          {car.transmission}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <GiPathDistance className="mr-1 text-primary" />
                        <p className="flex items-center text-[10px] text-slate-600 bg-green-200 px-[6px] py-[1px] rounded-full">
                          {car.km} KM
                        </p>
                      </div>
                    </div>
                    {!soldout && (
                      <CalculateCarEmi
                        totalPrice={car.price}
                        downPaymentPercentage={car.down_payment}
                      />
                    )}
                    <div className="flex items-center justify-between mt-4">
                      <div className="flex items-centers">
                        <svg
                          className="w-4 h-4 mr-2 text-gray-600 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 0C8.301 0 5.189 3.112 5.189 6.911c0 5.472 5.591 11.526 6.462 12.571.21.293.56.293.771 0 .89-1.245 6.462-7.1 6.462-12.571C18.811 3.112 15.699 0 12 0zm0 9.864c-1.733 0-3.138-1.405-3.138-3.138 0-1.733 1.405-3.138 3.138-3.138 1.733 0 3.138 1.405 3.138 3.138 0 1.733-1.405 3.138-3.138 3.138z" />
                        </svg>
                        <p className="text-[12px] text-gray-600">
                          {car.district.name}
                        </p>
                      </div>
                      <span className="text-[12px] font-semibold text-gray-800">
                        {formatter.format(car.price)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
        {renderButton && (
          <div className="flex items-end justify-end pr-6">
             <Link to={`${soldout ? "/soldout/cars" : "/cars"}`}>
            <div className=" p-3 text-sm font-bold tracking-wide text-gray-100 uppercase bg-indigo-500 rounded-lg focus:outline-none focus:shadow-outline">
              View All
            </div>
          </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllCarList;

export function SoldOutBadge() {
  return (
    <div
      className="absolute top-0 left-0 flex items-center justify-center w-full h-full text-xs font-semibold text-white"
      style={{ background: `rgba(255, 0, 0, 0.2)` }}
    >
      <div className="rounded-[50%] h-[100px] w-[100px] flex items-center justify-center bg-white px-1 py-1 rotate-[-20deg]">
        <img src={SoldOutImage} alt="soldout" />
      </div>
    </div>
  );
}

function createSlug(title) {
  return title
    .toLowerCase()
    .replace(/[^\w\s-]/g, "")
    .replace(/\s+/g, "-")
    .replace(/--+/g, "-")
    .trim();
}
