import {React, useEffect} from 'react'
import Header from './partials/Header';
import Footer from './partials/Footer';
import ContactComponent from './partials/Contact';
const Contact = () => {

    useEffect(() => {
        document.title = 'Contact Us - Your Car Bazar'
      }, []);

    return (
        <div>
            <Header />
            <ContactComponent />
            <Footer />
        </div>
    )
}

export default Contact