import { React, useEffect, useState } from "react";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import Carousel from "react-multi-carousel";
import { FaPlay } from "react-icons/fa6";
import axios from "axios";
import { useParams } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import HeroSliderBlock from "./hero/HeroSliderBlock";
import EmiCalculator from "./emi/EmiCalculator";
const CarDetail = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiBaseUri = process.env.REACT_APP_BASE_URL;
  const [carData, setCarData] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl + "getCarDetail/" + id);
        setCarData(response.data.data);
      } catch (error) {
        console.error("Error fetching car data:", error);
      }
    };
    fetchData();
  }, []);

  const responsiveVideo = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [formData, setFormData] = useState({
    id: id,
    name: "",
    email: "",
    message: "",
    mobile: "",
    subject: "",
  });
  const [formDataError, setFormDataError] = useState({
    name: "",
    email: "",
    message: "",
    mobile: "",
    subject: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(apiUrl + "storeContactInfo", formData);
      console.log(response.data);
      setFormData({
        name: "",
        email: "",
        message: "",
        mobile: "",
        subject: "",
      });
      setFormDataError({
        name: "",
        email: "",
        message: "",
        mobile: "",
        subject: "",
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const validationErrors = error.response.data.errors;
        const newErrors = {};
        Object.keys(validationErrors).forEach((key) => {
          newErrors[key] = validationErrors[key][0];
        });
        setFormDataError(newErrors);
      } else {
        console.error("Error saving data:", error);
      }
    }
  };

  return (
    <div>
      <Header />
      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mt-8">
        {carData && carData.vehicle_model && (
          <div>
            <div className=" py-3  mb-1.5 rounded bg-white px-5">
              <div>
                <h3 className="text-fill-base text-[16px] lg:leading-7 font-bold uppercase">
                  {carData.vehicle_model !== null
                    ? carData.vehicle_model.name
                    : ""}
                  {carData.vehicle_variant !== null
                    ? carData.vehicle_variant.name
                    : null}
                </h3>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-5 my-6 md:grid-cols-2">
              {carData.status == "Sold Out" || !carData.is_loan ? (
                <CarFeatureAndDetail carData={carData} />
              ) : (
                <EmiCalculator
                  totalPrice={carData.price}
                  downPaymentPercentage={carData.down_payment}
                />
              )}

              <HeroSliderBlock
                soldout={carData.status == "Sold Out"}
                data={
                  carData.status == "Sold Out"
                    ? [
                        ...carData.images.filter(
                          (image) => image.type === "Sold Out"
                        ),
                        ...carData.images.filter(
                          (image) => image.type !== "Sold Out"
                        ),
                      ]
                    : carData.images
                }
                type={"dynamic"}
                variant="slider"
                className={``}
                contentClassName="p-7 sm:py-18 xl:py-16 sm:px-16 xl:px-24 md:h-[270px] xl:h-[500px] rounded"
              />
            </div>
            <div
              className={`grid ${
                carData.status != "Sold Out"
                  ? "grid-cols-1 md:grid-cols-2"
                  : "grid-cols-1"
              } gap-5`}
            >
              {carData.status !== "Sold Out" && (
                <CarFeatureAndDetail carData={carData} />
              )}
              {carData.status != "Sold Out" && (
                <div className="w-full p-4 overflow-hidden bg-white rounded">
                  <form onSubmit={handleSubmit}>
                    <div className="px-2 py-2">
                      <h3 className="text-fill-base text-[16px] lg:leading-7 font-bold uppercase mb-3">
                        Submit Your Booking Request
                      </h3>
                      <div className="grid grid-cols-2 gap-5">
                        <div>
                          <input
                            className="bg-gray-50 border py-2 px-4 w-full appearance-none transition duration-150 ease-in-out border text-input text-13px lg:text-sm font-body rounded placeholder-[#B3B3B3] min-h-12  text-primary-dark focus:ring-0  border-border-two focus:border-1 focus:outline-none focus:border-primary h-11 md:h-12"
                            type="text"
                            name="name"
                            onChange={handleChange}
                            value={formData.name}
                            placeholder="Enter Name"
                          />
                          {formDataError.name !== "" && (
                            <span className="text-red-600 error">
                              {formDataError.name}
                            </span>
                          )}
                        </div>
                        <div className="mt-2">
                          <input
                            className="bg-gray-50 border py-2 px-4 w-full appearance-none transition duration-150 ease-in-out border text-input text-13px lg:text-sm font-body rounded placeholder-[#B3B3B3] min-h-12  text-primary-dark focus:ring-0  border-border-two focus:border-1 focus:outline-none focus:border-primary h-11 md:h-12"
                            type="text"
                            name="email"
                            placeholder="Enter Email"
                            onChange={handleChange}
                            value={formData.email}
                          />
                          {formDataError.email !== "" && (
                            <span className="text-red-600 error">
                              {formDataError.email}
                            </span>
                          )}
                        </div>
                        <div className="mt-2">
                          <input
                            className="bg-gray-50 border py-2 px-4 w-full appearance-none transition duration-150 ease-in-out border text-input text-13px lg:text-sm font-body rounded placeholder-[#B3B3B3] min-h-12  text-primary-dark focus:ring-0  border-border-two focus:border-1 focus:outline-none focus:border-primary h-11 md:h-12"
                            type="text"
                            name="mobile"
                            placeholder="Enter Mobile"
                            onChange={handleChange}
                            value={formData.mobile}
                          />
                          {formDataError.mobile !== "" && (
                            <span className="text-red-600 error">
                              {formDataError.mobile}
                            </span>
                          )}
                        </div>
                        <div className="mt-2">
                          <input
                            className="bg-gray-50 border py-2 px-4 w-full appearance-none transition duration-150 ease-in-out border text-input text-13px lg:text-sm font-body rounded placeholder-[#B3B3B3] min-h-12  text-primary-dark focus:ring-0  border-border-two focus:border-1 focus:outline-none focus:border-primary h-11 md:h-12"
                            type="text"
                            name="subject"
                            placeholder="Enter Subject"
                            onChange={handleChange}
                            value={formData.subject}
                          />
                          {formDataError.subject !== "" && (
                            <span className="text-red-600 error">
                              {formDataError.subject}
                            </span>
                          )}
                        </div>
                        <div className="col-span-2 mt-2">
                          <textarea
                            className="bg-gray-50 border py-2 px-4 w-full appearance-none transition duration-150 ease-in-out border text-input text-13px lg:text-sm font-body rounded placeholder-[#B3B3B3] min-h-32  text-primary-dark focus:ring-0  border-border-two focus:border-1 focus:outline-none focus:border-primary h-11 md:h-12"
                            placeholder="Enter Message"
                            name="message"
                            onChange={handleChange}
                            value={formData.message}
                          />
                          {formDataError.message !== "" && (
                            <span className="text-red-600 error">
                              {formDataError.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="mt-2">
                        <button className="w-full p-3 text-sm font-bold tracking-wide text-gray-100 uppercase bg-indigo-500 rounded-lg focus:outline-none focus:shadow-outline">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
            <div className="pb-4">
              <div className=" py-3  mb-1.5 rounded bg-white px-5 mt-5">
                <div>
                  <h3 className="text-fill-base text-[16px] lg:leading-7 font-bold uppercase">
                    Available Interior Videos
                  </h3>
                </div>
              </div>
            </div>
            <Carousel
              responsive={responsiveVideo}
              showDots={false}
              className=" mb-7"
              renderArrowsWhenDisabled={true}
              infinite={true}
              itemClass="px-4"
            >
              {carData.videos.map((video) => {
                if (video.video_type === "Car" && video.link !== null) {
                  return (
                    <a href={video.link} target="yourcarbazar" key={video.id}>
                      <div className="bg-white rounded min-h-[300px]">
                        <div className="flex flex-col">
                          <div className="relative h-[250px]">
                            <img
                              className="h-[100%] w-[100%] object-cover"
                              src={
                                video.image
                                  ? `${apiBaseUri}file/uploads/${btoa(
                                      "car-video"
                                    )}/${btoa(
                                      video.image ? video.image : "54654"
                                    )}`
                                  : "https://placehold.co/600x400/EEE/31343C"
                              }
                              alt="car-video"
                            />
                            <div
                              className="absolute top-0 left-0 h-[100%] w-[100%] flex items-center justify-center text-white text-3xl"
                              style={{ background: `rgba(0, 0, 0, 0.3)` }}
                            >
                              <FaPlay />
                            </div>
                          </div>
                          <h2 className="text-fill-base text-[14px] truncate font-bold my-2 mx-2">
                            {video.title}
                          </h2>
                        </div>
                      </div>
                    </a>
                  );
                } else {
                  return null;
                }
              })}
            </Carousel>
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};
export function extractYouTubeVideoId(url) {
  if (url) {
    const urlObj = new URL(url);
    let videoId;
    if (urlObj.searchParams.get("v")) {
      videoId = urlObj.searchParams.get("v");
    } else {
      const pathSegments = urlObj.pathname.split("/");
      videoId = pathSegments[pathSegments.length - 1];
    }

    if (videoId) {
      return videoId;
    } else {
      throw new Error("Invalid YouTube URL");
    }
  }
}

export function getYouTubeThumbnailUrl(videoUrl) {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|v\/|.+\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = videoUrl.match(regex);

  if (match && match[1]) {
    return `https://img.youtube.com/vi/${match[1]}/maxresdefault.jpg`;
  } else {
    return null;
  }
}
export default CarDetail;

const CarFeatureAndDetail = ({ carData }) => {
  return (
    <div className="overflow-hidden rounded">
      {carData && carData.vehicle_model && (
        <div className="px-2 py-2 rounded-lg bg-gray-50">
          <h5 className="mb-6 text-2xl font-semibold text-primary">
            Car Details
          </h5>
          <table className="w-full overflow-hidden border border-separate border-gray-300 rounded-lg border-spacing-2">
            <tbody>
              <tr className="transition-colors duration-200 bg-gray-50">
                <td className="px-2 py-2 text-gray-600">Model</td>
                <td className="px-2 py-2">
                  {carData.vehicle_variant !== null
                    ? carData.vehicle_variant.name
                    : "" || "N/A"}
                </td>
              </tr>
              <tr className="transition-colors duration-200 bg-white">
                <td className="px-2 py-2 text-gray-600">Condition</td>
                <td className="px-2 py-2">{carData.title}</td>
              </tr>
              <tr className="transition-colors duration-200 bg-white">
                <td className="px-2 py-2 text-gray-600">Year</td>
                <td className="px-2 py-2">
                  {carData.month !== null ? carData.month + "-" : ""}
                  {carData.year.length === 2
                    ? `20${carData.year}`
                    : carData.year}
                </td>
              </tr>
              <tr className="transition-colors duration-200 bg-gray-50">
                <td className="px-2 py-2 text-gray-600">KM</td>
                <td className="px-2 py-2">{carData.km}</td>
              </tr>
              <tr className="transition-colors duration-200 bg-white">
                <td className="px-2 py-2 text-gray-600">Fuel Type</td>
                <td className="px-2 py-2">{carData.fuel_type}</td>
              </tr>
              <tr className="transition-colors duration-200 bg-gray-50">
                <td className="px-2 py-2 text-gray-600">Transmission</td>
                <td className="px-2 py-2">{carData.transmission}</td>
              </tr>
              <tr className="transition-colors duration-200 bg-white">
                <td className="px-2 py-2 text-gray-600">No. Of Owners</td>
                <td className="px-2 py-2">{carData.no_of_owner}</td>
              </tr>
              <tr className="transition-colors duration-200 bg-white">
                <td className="px-2 py-2 text-gray-600">RTO</td>
                <td className="px-2 py-2">{carData.district.name}</td>
              </tr>
              <tr className="transition-colors duration-200 bg-gray-50">
                <td className="px-2 py-2 text-gray-600">Price</td>
                <td className="px-2 py-2 font-semibold text-gray-900">
                  ₹ {carData.price.toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
