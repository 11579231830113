import React from 'react'
import Header from './partials/Header';
import Footer from './partials/Footer';
const TermAndCondition = () => {
  return (
    <div>
     <Header />
     <div className="container mx-auto px-4 my-5 max-w-screen-lg">
      <div className="mb-4">
        <h3 className="text-xl font-bold mb-2">1. Vehicle Images</h3>
        <p>
          The Vehicle images are as posted by the respective Dealer. The Vehicle images are for representation purpose only and are subject to variation in color, appearance due to photographic effects, lighting conditions etc. at the time of taking the image.
        </p>
      </div>
      <div className="mb-4">
        <h3 className="text-xl font-bold mb-2">2. Vehicle Inspection</h3>
        <p>
          The customer shall inspect the Vehicle thoroughly and satisfy himself/herself about the condition of the Vehicle before concluding the purchase formalities. No claim of whatsoever nature concerning Vehicle performance or Vehicle quality except inherent warrantable defects (provided the Vehicle is under warranty) shall be entertained by the Dealer after the Vehicle is sold.
        </p>
      </div>
      <div className="mb-4">
        <h3 className="text-xl font-bold mb-2">3. Taxes and Charges</h3>
        <p>
          Any taxes/ charges incurred in carrying out the sale transaction shall be borne by the customer, as applicable at the time of sale.
        </p>
      </div>
      <div className="mb-4">
        <h3 className="text-xl font-bold mb-2">4. Delivery and Formalities</h3>
        <p>
          Delivery of the Vehicle by the Dealer shall only be made against 100% payment and completion of all necessary formalities to the satisfaction of the Dealer. The sale and delivery of Vehicle shall be subject to fulfillment of all applicable statutory obligations and submission of requisite supporting documents. The Customer shall produce the originals of the requisite documents for RTO registration etc. at the Dealership. In the event of the customer’s failure to ensure compliance of these requirements, the booking amount paid by the customer may be forfeited by the Dealer.
        </p>
      </div>
    </div>
      <Footer />   
    </div>
  )
}

export default TermAndCondition
