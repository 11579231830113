import React, { useEffect } from 'react'
import Header from './partials/Header';
import Footer from './partials/Footer';
import AboutComponent from './partials/About';

function About() {

    useEffect(() => {
        document.title = 'About Us - Your Car Bazar'
    }, []);

    return (
        <div>
            <Header />
            <AboutComponent />
            <Footer />
        </div>
    )
}

export default About;