import React from "react";
import Shimmer from "./Shimmer";

const ShimmerCardList = ({length}) => {
  return (
      <div className="grid grid-cols-1 gap-5 xl:grid-cols-5 lg:grid-cols-4 sm:grid-cols-2 md:grid-cols-3">
        {Array.from({ length: length }).map((_, index) => (
          <Shimmer key={index} />
        ))}
    </div>
  );
};

export default ShimmerCardList;
