import { React, useEffect, useState } from 'react'
import Select from 'react-select';
import axios from 'axios';
const Search = ({ onSearchChange }) => {
    const [stateData, setStateData] = useState([]);
    const [districtList, setdistrictList] = useState([]);
    const [modelList, setmodelList] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;
    useEffect(() => {
        const getStateList = async () => {
            try {
                const response = await axios.get(apiUrl + 'getStateList?type=dataonly');
                if (response.data && response.data.data && response.data.data.length > 0) {
                    const newStateData = response.data.data.map(item => ({
                        value: item.id,
                        label: item.name
                    }));
                    setStateData(newStateData);
                }
            } catch (error) {
                console.error('Error fetching state data:', error);
            }
        };
        getStateList();
        const getModelList = async () => {
            try {
                const response = await axios.get(apiUrl + 'getModelList');
                if (response.data && response.data.data && response.data.data.length > 0) {
                    const newModelData = response.data.data.map(item => ({
                        value: item.id,
                        label: item.name
                    }));
                    setmodelList(newModelData);
                }
            } catch (error) {
                console.error('Error fetching vehicle model data:', error);
            }
        };
        getModelList();
    }, []);
    const getDistrictList = async (stateID) => {
        try {
            const response = await axios.get(apiUrl + "getDistrictList/" + stateID + '?type=dataonly');
            if (response.data && response.data.data && response.data.data.length > 0) {
                const districtListNew = response.data.data.map(item => ({
                    value: item.id,
                    label: item.name
                }));
                setdistrictList(districtListNew);
            }
        } catch (error) {
            console.error('Error fetching district data:', error);
        }
       
    };

    const fuelType = [
        { value: 'Diesel', label: 'Diesel' },
        { value: 'Petrol', label: 'Petrol' },
        { value: 'CNG', label: 'CNG' },
        { value: 'Hybrid', label: 'Hybrid' },
        { value: 'Electric', label: 'Electric' },
        { value: 'Ethanol', label: 'Ethanol' },
        { value: 'LPG', label: 'LPG' },
        { value: 'Hydrogen', label: 'Hydrogen' },
    ];

    const [stateSelected, setStateSelected] = useState(null);

    const handleStateChange = (selectedOption) => {
        setStateSelected(stateSelected);
        getDistrictList(selectedOption.value); 
    };
    const [districtSelected, setDistrictSelected] = useState(null);

    const handleDistrictChange = (selectedOption) => {
        setDistrictSelected(districtSelected);
         onSearchChange({ districtSelected: selectedOption.value });
    };
    const [modelSelected, setModelSelected] = useState(null);

    const handleModelChange = (selectedOption) => {
        setModelSelected(modelSelected);
         onSearchChange({modelSelected: selectedOption.value});
    };
    const [fuelTypeSelected, setFuelTypeSelected] = useState(null);

    const handleFuelTypeChange = (selectedOption) => {
        setFuelTypeSelected(fuelTypeSelected);
         onSearchChange({ fuelTypeSelected: selectedOption.value });
    };

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 justify-center bg-white">
            <Select
                defaultValue={stateSelected}
                onChange={handleStateChange}
                options={stateData}
            />
            <Select
                defaultValue={districtSelected}
                onChange={handleDistrictChange}
                options={districtList}
            />
            <Select
                defaultValue={modelSelected}
                onChange={handleModelChange}
                options={modelList}
            />
            <Select
                defaultValue={fuelTypeSelected}
                onChange={handleFuelTypeChange}
                options={fuelType}
            />
        </div>
    );
}


export default Search