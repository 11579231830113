import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { SoldOutBadge } from "../partials/AllCarList";
function getImage(imgObj) {
  return imgObj;
}
export default function HeroBannerCard({
  banner,
  className = "py-20 xy:pt-24",
  variant,
  type,
  soldout = false,
}) {
  const apiBaseUri = process.env.REACT_APP_BASE_URL;
  const { image, title, description, btnUrl, btnText } = banner;
  const selectedImage =
    type == "static"
      ? getImage(image)
      : `${apiBaseUri}file/uploads/${btoa("car-image")}/${btoa(
          image ? image : "54654"
        )}`;

  return (
    <div
      className={cn(
        "w-full bg-no-repeat bg-cover bg-center flex items-center rounded relative",
        {
          "min-h-[320px] md:min-h-[367px]": variant === "slider",
        },
        className
      )}
      style={{
        backgroundImage: `url('${selectedImage}')`,
      }}
    >
      {soldout && <SoldOutBadge />}
      <div
        className={cn(
          "sm:absolute inset-0 m-[15px] md:mt-[30px] xl:mt-[50px] w-full",
          {
            "mx-auto max-w-[480px] md:max-w-[580px] xl:max-w-[700px]":
              variant === "slider",
            "mx-auto max-w-[480px] md:max-w-[580px] xl:max-w-[600px]":
              variant === "antique",
            "lg:px-20 max-w-[480px] md:max-w-[580px] xl:max-w-[700px]":
              variant === "slider-4",
          }
        )}
      >
        <div
          className={cn("text-left ", {
            "md:w-8/12 lg:w-6/12": variant === "slider",
            "text-left": variant === "slider-4",
          })}
        >
          {description && (
            <p
              className={cn("text-[12px] leading-7 uppercase font-bold ", {
                "text-white ": variant === "default",
                "text-white": variant === "slider",
                "": variant === "antique",
              })}
            >
              {description}
            </p>
          )}
          {title && (
            <h2
              style={{
                textShadow: `0 0 5px #f00, 0 0 10px #f00, 0 0 20px #f00, 0 0 40px #f00, 0 0 80px #f00`,
              }}
              className={cn(
                "text-4xl md:text-4xl font-semibold mt-2 leading-8",
                {
                  "xl:text-5xl 2xl:text-[36px] text-white leading-snug md:leading-tight xl:leading-[1.3em] mb-3 md:mb-4 xl:mb-3 ":
                    variant !== "antique",
                  "text-white 2xl:text-[36px]": variant === "default",
                  "text-white 2xl:text-[36px] ": variant === "antique",
                }
              )}
            >
              <Typewriter text={title} speed={100} />
            </h2>
          )}

          {btnText && (
            <Link
              to={btnUrl}
              className={cn(
                " h-[44px] mt-5 md:mt-12 text-base inline-flex items-center justify-center transition duration-300 rounded px-10 py-2 font-semibold ",
                {
                  "text-fill-base bg-white hover:text-white hover:bg-primary ":
                    variant !== "antique",
                  "text-brand-light bg-brand hover:text-white hover:bg-white ":
                    variant === "antique",
                }
              )}
            >
              {btnText}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
const Typewriter = ({ text = "", speed = 100 }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [isTyping, setIsTyping] = useState(true);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (text.length === 0) return;

    let index = 0;
    setDisplayedText("");
    setIsTyping(true);

    intervalRef.current = setInterval(() => {
      setDisplayedText((prev) => {
        if (index >= text.length) {
          clearInterval(intervalRef.current);
          setIsTyping(false);
          return prev;
        }
        return prev + text[index++];
      });
    }, speed);

    return () => clearInterval(intervalRef.current);
  }, [text, speed]);

  return <div>{displayedText}</div>;
};
