import { React, useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const Feedback = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [feedback, setFeedback] = useState([]);
  const responsiveVideo = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const getCustomerFeedback = async () => {
    try {
      const response = await axios.get(apiUrl + "getCustomerFeedback");
      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        setFeedback(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching state data:", error);
    }
  };
  useEffect(() => {
    getCustomerFeedback();
  }, []);
  return (
    <div>
      <Header />
      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0">
      <div className="px-5 py-3 my-4 bg-white rounded">
        <div className="flex flex-col">
          <h3 className="text-fill-base text-[16px] lg:leading-7 font-bold uppercase">
            {"Customer Feedback"}
          </h3>
        </div>
      </div>
      <Carousel
        responsive={responsiveVideo}
        showDots={false}
        renderArrowsWhenDisabled={true}
        infinite={true}
        itemClass="px-4"
      >
        {feedback.map((video) => {
          if (video.video_type === "Feedback") {
            return (
              <div
                className="overflow-hidden relative h-[300px] bg-red-200"
                key={video.id}
              >
                <div className="bg-white rounded-lg shadow-lg overflow-hidden  h-[300px]">
                  <iframe
                    title={`video-${video.id}`}
                    width="100%"
                    height="100%"
                    src={`https://www.youtube.com/embed/${extractYouTubeVideoId(
                      video.link
                    )}`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}
      </Carousel>
      </div>
      <Footer />
    </div>
  );
};
function extractYouTubeVideoId(link) {
  const pattern =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = link.match(pattern);
  return match ? match[1] : null;
}
export default Feedback;
