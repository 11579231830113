import { Routes, Route } from "react-router-dom";
import Home from "../components/Home";
import About from "../components/About";
import HowItWorks from "../components/HowItWorks";
import CarList from "../components/CarList";
import Contact from "../components/Contact";
import SellYourCar from "../components/SellYourCar";
import CarDetail from "../components/CarDetail";
import PrivacyAndPolicy from "../components/PrivacyAndPolicy";
import TermAndCondition from "../components/TermAndCondition";
import Feedback from "../components/partials/Feedback";
import SoldOutCar from "../components/SoldOutCar";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/how-it-works" element={<HowItWorks />} />
      <Route path="/cars" element={<CarList />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/soldout/cars" element={<SoldOutCar />} />
      <Route path="/about" element={<About />} />
      <Route path="/feedback" element={<Feedback />} />
      <Route path="/sell-your-car" element={<SellYourCar />} />
      <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
      <Route path="/terms-condition" element={<TermAndCondition />} />
      <Route path="/car-detail/:id/:title" element={<CarDetail />} />
    </Routes>
  );
}
