import { React, useEffect } from "react";
import { FaFileAlt, FaInfoCircle, FaUserCog, FaCar } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
const ServiceCard = () => {
  useEffect(() => {
    AOS.init({
      offset: 50,
      duration: 500,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  const services = [
    {
      icon: <FaFileAlt size={30} />,
      title: "RTO Records Check",
      description:
        "Access comprehensive RTO records for any vehicle by providing its registration number. Get details such as ownership history...",
    },
    {
      icon: <FaInfoCircle size={30} />,
      title: "Vehicle History Report",
      description:
        "Obtain a detailed vehicle history report to uncover any past accidents, thefts, or damages. Our reports include information about insurance claims...",
    },
    {
      icon: <FaUserCog size={30} />,
      title: "Owner Transfer Assistance",
      description:
        "Need help with transferring vehicle ownership? Our experts can assist you throughout the process, from preparing the necessary...",
    },
    {
      icon: <FaCar size={30} />,
      title: "Vehicle Inspection Services",
      description:
        "Schedule a comprehensive vehicle inspection before purchasing a used car. Our experienced technicians will thoroughly examine the...",
    },
  ];
  return (
    <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0">
      <div className="px-5 py-3 my-4 bg-white rounded">
        <div className="flex flex-col">
          <h3 className="text-fill-base text-[16px] lg:leading-7 font-bold uppercase">
            {"Services We Offer"}
          </h3>
          <p className=" text-[12px] ">
            Your don't need to go dealer to dealer .you just need to visit our
            website and submit your car detait and its just take few minutes
          </p>
        </div>
      </div>

      <div className="grid items-center justify-around gap-5 mb-8 xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2">
        {services.map((service, index) => (
          <div
            key={index}
            className="cursor-pointer max-w-sm rounded overflow-hidden shadow-lg group bg-white mx-auto hover:bg-[#173160] duration-1000"
            data-aos="zoom-in"
          >
            <div className="px-6 py-4">
              <div className="flex flex-col items-center">
                <span className="text-3xl text-accent animate-bounce">
                  {service.icon}
                </span>
                <div className="ml-4">
                  <div className="font-bold text-[16px] text-center py-2 group-hover:text-white">
                    {service.title}
                  </div>
                  <p className="text-slate-500  text-center text-[14px] group-hover:text-white">
                    {service.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceCard;
