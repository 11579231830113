import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/Routes.js";
import './assets/index.css';
function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);

