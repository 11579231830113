import React, { useState } from "react";
import Logo from "../../logo.png";
import NavLinks from "./NavLinks";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoCloseOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
const NavBar = () => {
  const [open, SetOpen] = useState(false);
  return (
    <nav className="bg-white shadow">
      <div className="flex items-center font-medium text-[12px] justify-between px-5">
        <div className="z-50 p-5 md:2-auto md:w-[30%] w-full md:block flex justify-between items-center">
          <Link to="/">
            {" "}
            <img src={Logo} className="h-[30px] w-[150px]" alt="logo" />
          </Link>
          <div className="block text-2xl cursor-pointer md:hidden my-7">
            {open ? (
              <IoCloseOutline
                className="text-grey-500"
                onClick={() => SetOpen(false)}
              />
            ) : (
              <GiHamburgerMenu
                className="text-grey-500"
                onClick={() => SetOpen(true)}
              />
            )}
          </div>
        </div>
        <ul className=" uppercase items-center font[Poppins] md:flex hidden">
          <NavLinks />
        </ul>
        {/* Mobile Nav */}
        <ul
          className={`md:hidden bg-white absolute w-full h-full z-10  bottom-0 py-24 pl-4 duration-500 ${
            open ? "left-0" : "left-[-100%]"
          }`}
        >
          <NavLinks />
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
