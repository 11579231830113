import React, { useState, useEffect } from "react";
import Footer from "./partials/Footer";
import Header from "./partials/Header";
import Heading from "./partials/Heading";
import AOS from "aos";
import axios from "axios";

const SellYourCar = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isOptionLoaded, setIsOptionLoaded] = useState(null);
  const [stateData, setStateData] = useState([]);
  const [districtList, setdistrictList] = useState([]);
  const [companyList, setcompanyList] = useState([]);
  const [vehicleList, setvehicleList] = useState([]);
  const [modelList, setmodelList] = useState([]);
  const [images, setImages] = useState();
  const [carVideo, setCarVideo] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const fuelType = [
    { value: "Diesel", label: "Diesel" },
    { value: "Petrol", label: "Petrol" },
    { value: "CNG", label: "CNG" },
    { value: "Hybrid", label: "Hybrid" },
    { value: "Electric", label: "Electric" },
    { value: "Ethanol", label: "Ethanol" },
    { value: "LPG", label: "LPG" },
    { value: "Hydrogen", label: "Hydrogen" },
  ];
  const transmission = [
    { value: "Automatic", label: "Automatic" },
    { value: "Mannual", label: "Mannual" },
  ];
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = currentYear; year >= currentYear - 30; year--) {
    years.push({ value: year.toString(), label: year.toString() });
  }
  const formAttributes = [
    {
      name: "phone",
      label: "Mobile No.",
      type: "text",
      placeholder: "Enter Mobile No.",
    },
    { name: "name", label: "Name", type: "text", placeholder: "Enter Name" },
    {
      name: "whatsapp",
      label: "Whatsapp No.",
      type: "text",
      placeholder: "Enter Whatsapp No.",
    },
    {
      name: "company_id",
      label: "Select Company",
      type: "select",
      placeholder: "Select Company",
      options: companyList,
    },
    {
      name: "vehicle_id",
      label: "Select Brand",
      type: "select",
      placeholder: "Select Brand",
      options: vehicleList,
    },
    {
      name: "model_id",
      label: "Model",
      type: "select",
      placeholder: "Select Model",
      options: modelList,
    },
    {
      name: "fuel_type",
      label: "Fuel Type",
      type: "select",
      placeholder: "Select Fuel Type",
      options: fuelType,
    },

    {
      name: "state_id",
      label: "State",
      type: "select",
      placeholder: "Select State",
      options: stateData,
    },
    {
      name: "district_id",
      label: "District",
      type: "select",
      placeholder: "Select District",
      options: districtList,
    },
    {
      name: "year",
      label: "Year",
      type: "select",
      placeholder: "Purchase Year",
      options: years,
    },
    { name: "km", label: "KM Driven", type: "text", placeholder: "KM Driven" },
    {
      name: "no_of_owner",
      label: "No of Owners",
      type: "text",
      placeholder: "Enter No of Owners",
    },
    {
      name: "reg_no",
      label: "Registration No.",
      type: "text",
      placeholder: "Enter Registration No.",
    },
    {
      name: "transmission",
      label: "Transmission",
      type: "select",
      placeholder: "Select Transmission",
      options: transmission,
    },
    { name: "price", label: "Price", type: "text", placeholder: "Enter Price" },
    {
      name: "car_video",
      label: "Recorded Video",
      type: "file",
      placeholder: "Choose Car Video",
      accept: "video/*",
    },
    {
      name: "images[]",
      label: "Car Image",
      type: "file",
      placeholder: "Choose Car Image",
      multiple: true,
      accept: "image/*",
    },
  ];

  useEffect(() => {
    const getStateList = async () => {
      try {
        const response = await axios.get(apiUrl + "getStateList");
        if (
          response.data &&
          response.data.data &&
          response.data.data.length > 0
        ) {
          const newStateData = response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setStateData(newStateData);
        }
      } catch (error) {
        console.error("Error fetching state data:", error);
      }
    };
    getStateList();
    const getCompanyList = async () => {
      try {
        const response = await axios.get(apiUrl + "getCompanyList");
        if (
          response.data &&
          response.data.data &&
          response.data.data.length > 0
        ) {
          const newModelData = response.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          setcompanyList(newModelData);
          setIsOptionLoaded(true);
        }
      } catch (error) {
        console.error("Error fetching vehicle model data:", error);
      }
    };
    getCompanyList();
    AOS.init({
      offset: 50,
      duration: 500,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  const getVehicleList = async (companyId) => {
    try {
      const response = await axios.get(apiUrl + "getVehicleList/" + companyId);
      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        const newModelData = response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setvehicleList(newModelData);
      }
    } catch (error) {
      console.error("Error fetching vehicle model data:", error);
    }
  };
  const getModelList = async (vehicleId) => {
    try {
      const response = await axios.get(apiUrl + "getModelList/" + vehicleId);
      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        const newModelData = response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setmodelList(newModelData);
      }
    } catch (error) {
      console.error("Error fetching vehicle model data:", error);
    }
  };

  const getDistrictList = async (stateID) => {
    try {
      const response = await axios.get(apiUrl + "getDistrictList/" + stateID);
      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        const districtListNew = response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setdistrictList(districtListNew);
      }
    } catch (error) {
      console.error("Error fetching district data:", error);
    }
  };

  const [formData, setFormData] = useState({
    phone: "",
    name: "",
    whatsapp: "",
    company_id: "",
    vehicle_id: "",
    model_id: "",
    fuel_type: "",
    state_id: "",
    district_id: "",
    year: "",
    km: "",
    no_of_owner: "",
    reg_no: "",
    transmission: "",
    price: "",
  });
  const [formErrors, setFormErrors] = useState({
    phone: "",
    name: "",
    whatsapp: "",
    company_id: "",
    vehicle_id: "",
    model_id: "",
    fuel_type: "",
    state_id: "",
    district_id: "",
    year: "",
    km: "",
    no_of_owner: "",
    reg_no: "",
    transmission: "",
    price: "",
    car_video: "",
  });

  const handleInputChange = (e) => {
    if (e.target.name == "state_id") {
      getDistrictList(e.target.value);
    }
    if (e.target.name == "company_id") {
      getVehicleList(e.target.value);
    }
    if (e.target.name == "vehicle_id") {
      getModelList(e.target.value);
    }
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleOnFileSelect = async (e) => {
    if (e.target.name == "car_video") {
      const files = Array.from(e.target.files);
      setCarVideo(files);
    } else {
      const files = Array.from(e.target.files);
      setImages(files);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors({
      phone: "",
      name: "",
      whatsapp: "",
      company_id: "",
      vehicle_id: "",
      model_id: "",
      fuel_type: "",
      state_id: "",
      district_id: "",
      year: "",
      km: "",
      no_of_owner: "",
      reg_no: "",
      transmission: "",
      price: "",
      car_video: "",
    });
    setIsSubmitting(true);
    try {
      const formDataToSend = new FormData();
      Object.keys(formData).forEach((key) => {
        formDataToSend.append(key, formData[key]);
      });
      if (images && images.length > 0) {
        images.forEach((image) => {
          formDataToSend.append("images[]", image);
        });
      }
      if (carVideo) {
        formDataToSend.append("car_video", carVideo[0]);
      }
      const response = await axios.post(
        apiUrl + "upload/lead/your-car-bazar",
        formDataToSend
      );
      setFormData({
        phone: "",
        name: "",
        whatsapp: "",
        company_id: "",
        vehicle_id: "",
        model_id: "",
        fuel_type: "",
        state_id: "",
        district_id: "",
        year: "",
        km: "",
        no_of_owner: "",
        reg_no: "",
        transmission: "",
        price: "",
        car_video: "",
      });
      setImages(null);
      setCarVideo(null);
      alert(response.data.msg);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        const validationErrors = error.response.data.errors;
        const newErrors = {};
        Object.keys(validationErrors).forEach((key) => {
          newErrors[key] = validationErrors[key][0];
        });
        setFormErrors(newErrors);
      } else {
        console.error("Error saving data:", error);
      }
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Header />
      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0">
      <div className="px-5 py-3 my-4 bg-white rounded">
        <div className="flex flex-col">
          <h3 className="text-fill-base text-[16px] lg:leading-7 font-bold uppercase">
            {"Get Fair Market Value for Your Old Car"}
          </h3>
        </div>
      </div>
      <div data-aos="flip-up">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <form onSubmit={handleSubmit}>
            <div className="grid md:grid-cols-3 gap-5 items-center">
              {isOptionLoaded &&
                formAttributes.map((attribute, index) => (
                  <div key={index}>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor={attribute.name}
                    >
                      {attribute.label}
                    </label>
                    {attribute.type !== "select" &&
                      attribute.type !== "file" && (
                        <input
                          className={`bg-gray-50 border py-2 px-4 w-full appearance-none transition duration-150 ease-in-out border text-input text-13px lg:text-sm font-body rounded placeholder-[#B3B3B3] min-h-12  text-primary-dark focus:ring-0  border-border-two focus:border-1 focus:outline-none focus:border-primary h-11 md:h-12 ${
                            formErrors[attribute.name] ? "border-red-500" : ""
                          }`}
                          id={attribute.name}
                          name={attribute.name}
                          type={attribute.type}
                          placeholder={attribute.placeholder}
                          value={formData[attribute.name]}
                          onChange={handleInputChange}
                          multiple={attribute.multiple}
                        />
                      )}
                    {attribute.type == "file" && (
                      <input
                        className={`bg-gray-50 border py-2 px-4 w-full appearance-none transition duration-150 ease-in-out border text-input text-13px lg:text-sm font-body rounded placeholder-[#B3B3B3] min-h-12  text-primary-dark focus:ring-0  border-border-two focus:border-1 focus:outline-none focus:border-primary h-11 md:h-12 ${
                          formErrors[attribute.name] ? "border-red-500" : ""
                        }`}
                        id={attribute.name}
                        name={attribute.name}
                        accept={attribute.accept}
                        type={attribute.type}
                        onChange={handleOnFileSelect}
                        multiple={attribute.multiple}
                      />
                    )}
                    {attribute.type === "select" && (
                      <select
                        className={`bg-gray-50 border py-2 px-4 w-full appearance-none transition duration-150 ease-in-out border text-input text-13px lg:text-sm font-body rounded placeholder-[#B3B3B3] min-h-12  text-primary-dark focus:ring-0  border-border-two focus:border-1 focus:outline-none focus:border-primary h-11 md:h-12 ${
                          formErrors[attribute.name] ? "border-red-500" : ""
                        }`}
                        id={attribute.name}
                        name={attribute.name}
                        value={formData[attribute.name]}
                        onChange={handleInputChange}
                      >
                        <option value="">{attribute.placeholder}</option>
                        {attribute.options.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    )}
                    {attribute.name === "images[]" ? (
                      <p className="text-red-500 text-xs italic">
                        {formErrors["images"]}
                      </p>
                    ) : (
                      <></>
                    )}
                    {formErrors[attribute.name] && (
                      <p className="text-red-500 text-xs italic">
                        {formErrors[attribute.name]}
                      </p>
                    )}
                  </div>
                ))}
            </div>
            <div className="mt-4">
              <button
                type="submit"
                className={`bg-primary text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                  isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
      </div>
      <Footer />
    </>
  );
};

export default SellYourCar;
