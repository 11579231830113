import { React, useEffect } from "react";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import AllCarList from "./partials/AllCarList";

const CarList = () => {
  useEffect(() => {
    document.title = "Find Your Car - Your Car Bazar";
  }, []);

  return (
    <div>
      <Header />
      <div className="py-8">
        <AllCarList heading={"Find Your Car"} filter={true} renderButton={false} slice={false} />
      </div>
      <Footer />
    </div>
  );
};

export default CarList;
